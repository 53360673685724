import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Figure from "../molecules/Figure"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import ButtonGroup from "../molecules/ButtonGroup"
import TextReveal from "../reveals/TextReveal"

const TextImageBlock = ({ block }) => {
  let imageWidth = ""
  let textWidth = ""

  if (block.image_size === "small") {
    imageWidth = "sm:w-1/2 lg:w-1/3"
    textWidth = "lg:w-2/3"
  } else if (block.image_size === "extra-small") {
    imageWidth = "sm:w-1/2 lg:w-1/4"
    textWidth = "lg:w-3/4"
  } else {
    imageWidth = "lg:w-1/2"
    textWidth = "lg:w-1/2"
  }

  return (
    <BlockWrapper
      block={block}
      showHeadline="false"
      className={block.bg_color ? "bg-roh-light-gray" : ""}
      blockPadding="py-20"
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className={`relative w-full mb-10 lg:mb-0 ${imageWidth} px-grid`}>
          {block.image?.filename && (
            <Figure
              image={block.image}
              caption={block.caption}
              aspectRatio={block.aspect_ratio}
              //className={`mx-auto ${imageWidth}`}
              className="mx-auto"
            />
          )}
        </div>
        <div
          className={`w-full ${textWidth} px-grid ${
            block.text_position === "left" ? "lg:order-first" : ""
          }`}
        >
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
            kickerClass={
              block.block_bg?.indexOf("text-white") !== -1 ? "text-white" : ""
            }
          />
          <TextReveal>
            <Richtext
              text={block.text}
              className={
                block.headline?.length > 0 || block.subheadline?.length > 0
                  ? "pt-8"
                  : ""
              }
            />
          </TextReveal>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default TextImageBlock
